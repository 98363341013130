
import { computed, defineComponent, ref, reactive, getCurrentInstance, onMounted } from "vue"
import { CollectionResourceDoc, RelationshipsWithData, ResourceObject } from '@/models/jsonapi'
import { Contract } from "@/models/contracts"
import { BvTableCtxObject } from "bootstrap-vue"
import { extractRelationshipIds, localIndex, fetchRelated } from "@/utils/apiDataHelper"
import { internalPostingSearchIndexColumns } from '@/view_scripts/table_columns/posting_search_columns'
import CareReceiverForm from "@/views/shared/CareReceiverForm.vue"
import { intersection } from "lodash"
import { fullName } from "@/utils/dataExtractors"

interface CareReceiverFormObject {
  mainQuery: reactive;
  zipCodeQuery: reactive;
  startDateQuery: reactive;
}

export default defineComponent({
  components: {
    CareReceiverForm
  },
  setup(_) {
    const root = getCurrentInstance().proxy

    const tableItems = ref([])
    const baseItems = ref([])
    const showLoading = ref(false)
    const totalItems = computed<number>(() => tableItems.value?.length || 0)
    const lastLoad = ref<Date|null>(null)

    const secondsSinceLastLoad = () => {
      if (!lastLoad.value) return 0
      const now = new Date()
      return Math.round((now.getTime() - lastLoad.value.getTime()) / 1000)
    }

    const staleTime = 180

    const careReceiverFields = ['dubCr', 'bedBound', 'ctDriver', 'ctLanguage', 'nightService', 'mobilityAssist', 'budget', 'budgetInt']

    const activePostingQuery = {
      'filter[active]': true,
      'fields[postings]': 'active,comitted,arrivalDate',
      'fields[addresses]': 'firstName,lastName,zipCode',
      'fields[care_receivers]': careReceiverFields.join(','),
      'fields[care_stays]': 'stage,billStart',
      'page[size]': -1,
      relationships: 'household,visors',
      include: 'household.care_receivers.address,household.lead.address,visors.care_stays'
    }

    const loadBaseItems = async () => {
      console.log('loadingBaseItems')
      return root.$store.dispatch('postings/loadv2', activePostingQuery).then((res) => {
        const index = localIndex(res.data)
        return res.data.data.map(posting => {
          const household = fetchRelated(posting, 'household', index)
          const leadAddress = fetchRelated(household, 'lead.address', index)
          const careReceivers = household.relationships.careReceivers.data.map((crRo) => index[crRo.id])
          const visorCareStayCounts = posting.relationships.visors.data.map((visor) => {
            const unresolvedStages = ['Prüfen', 'Neu', 'Vorgestellt']
            const now = new Date().getTime()
            return index[visor.id].relationships.careStays.data.map((csRo) => index[csRo.id]).filter((cs) => {
              return Date.parse(cs.attributes.billStart) > now && unresolvedStages.includes(cs.attributes.stage)
            }).length
          })
          const visorCount = visorCareStayCounts.length
          const unresolvedCareStayCount = visorCareStayCounts.reduce((a, b) => a + b, 0)
          const crAddresses = careReceivers.map((cr) => fetchRelated(cr, 'address', index))
          const crNames = crAddresses.map((address) => fullName(address)).join(' | ')
          const mergedCrs = careReceiverFields.reduce((res, field) => {
            res[field] = careReceivers.map((cr) => cr.attributes[field] || 'NA').join(' | ')
            return res
          }, {})

          showLoading.value = false
          return {
            postingRio: { id: posting.id, type: posting.type },
            ...posting.attributes,
            ...mergedCrs,
            leadRio: household.relationships.lead.data,
            leadName: leadAddress ? fullName(leadAddress): 'NA',
            careReceivers,
            // crNames,
            zipCodes: crAddresses.map((address) => address.attributes.zipCode || 'NA').join(' | '),
            zipCodeList: crAddresses.map((address) => address.attributes.zipCode).filter((e) => e),
            visorCount,
            unresolvedCareStayCount
          }
        })
      })
    }

    onMounted(async () => {
      console.log('mounted, loading baseItems')
      tableItems.value = await loadBaseItems()
      baseItems.value = tableItems.value
      lastLoad.value = new Date()
    })

    const searchItems = async (ctx: CareReceiverFormObject) => {
      const unfilteredItems = (secondsSinceLastLoad() > staleTime) ? await loadBaseItems() : baseItems.value
      if (Object.keys(ctx).length === 0) return tableItems.value = unfilteredItems
      tableItems.value = unfilteredItems.filter((item) => {
        return Object.entries(ctx).map(([key, value]) => {
          switch (key) {
            case 'zipCode':
              return item.zipCodeList.some(zipCode => zipCode.startsWith(value));
              break
            case 'ctLanguage':
              return !!item.careReceivers.find((cr) => { return cr.attributes[key].includes(value) })
              break
            case 'arrivalDate':
              return Date.parse(item.arrivalDate) >= Date.parse(value)
              break
            case 'budgetInt':
              console.log('budget', item.budgetInt, 'value', value)
              return item.budgetInt <= value
              break
            default:
              return !!item.careReceivers.find((cr) => {
                // console.log('key', key, 'value', value, 'cratt', cr.attributes[key], 'equal', cr.attributes[key] == value)
                return cr.attributes[key] == value
              })
              break
          }
        }).every((e) => e === true)
      })
    }
    const actions = {
      sendToMedea: (data: any) => {
        console.log('sendToMedea', data)
        showLoading.value = true
        root.$store.dispatch('postings/edit', {id: data.item.postingRio.id, body: { usecase: 'send_to_medea' }})
            .then((r) => { console.log('sendToMedea success', r) })
            .catch((e) => { console.log('sendToMedea error', e) })
            .finally(() => {
              console.log('sendToMedea finally')
              showLoading.value = false
            })
      }
    }
    const columns = ref(internalPostingSearchIndexColumns(actions))

    const titleActions = []

    return {
      searchItems,
      tableItems,
      totalItems,
      columns,
      titleActions,
      showLoading
    }
  }
})
