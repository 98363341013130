import { resourceUrl } from "@/utils/dataExtractors"
export const internalPostingSearchIndexColumns = (actions) => [
  {
    component: () => import("@/components/shared/table/cells/LinkCell.vue"),
    type: "Def",
    key: "leadName",
    title: "zum Lead",
    sortable: true,
    options: {
      hrefFunction: (data: any) => resourceUrl(data.item?.leadRio),
      contentFunction: (data: any) => data.item?.leadName || 'NA',
    }
  },
  {
    component: () => import("@/components/shared/table/cells/ValueCell.vue"),
    key: "visorCount",
    title: "Angefragte Agenturen",
    sortable: true,
  },
  {
    component: () => import("@/components/shared/table/cells/ValueCell.vue"),
    key: "unresolvedCareStayCount",
    title: "Aktive Vorschläge",
    sortable: true,
  },
  {
    component: () => import("@/components/shared/table/cells/ValueCell.vue"),
    key: "dubCr",
    title: "2 PbP",
    sortable: true,
  },
  {
    component: () => import("@/components/shared/table/cells/ValueCell.vue"),
    key: "bedBound",
    title: "Bettlägerig",
    sortable: true,
  },
  {
    component: () => import("@/components/shared/table/cells/ValueCell.vue"),
    key: "ctDriver",
    title: "Führerschein",
    sortable: true,
  },
  {
    component: () => import("@/components/shared/table/cells/ValueCell.vue"),
    key: "ctLanguage",
    title: "Sprachkenntnisse",
    sortable: true,
  },
  {
    component: () => import("@/components/shared/table/cells/ValueCell.vue"),
    key: "nightService",
    title: "Nachteinsätze",
    sortable: true,
  },
  {
    component: () => import("@/components/shared/table/cells/ValueCell.vue"),
    key: "mobilityAssist",
    title: "Transfer Unterstützung",
    sortable: true
  },
  {
    component: () => import("@/components/shared/table/cells/ValueCell.vue"),
    key: "budget",
    title: "Budget",
    sortable: true,
  },
  {
    component: () => import("@/components/shared/table/cells/ValueCell.vue"),
    key: "zipCodes",
    title: "Postleitzahl",
    sortable: true,
  },
  {
    component: () => import("@/components/shared/table/cells/ValueCell.vue"),
    key: "arrivalDate",
    title: "Arrival Date",
    sortable: true,
    options: { type: 'date' }
  },
  {
    component: () => import("@/components/shared/table/cells/ButtonsCell.vue"),
    key: "actions",
    title: "Actions",
    sortable: false,
    options: {
      buttons: [
        {
          title: 'Zu Medea',
          icon: ['fas', 'paper-plane'],
          action: actions.sendToMedea
        },
      ]
    }
  },
]